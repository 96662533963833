import React from "react"
import {Link} from "gatsby";

class ReadMoreBtn extends React.Component {

    render() {
        if(this.props.type === 'link'){
            return (<Link className={"btn btn-outline-light rounded-pill pl-4 pr-4 fsm-theme-small-text theme-bg-blue"} to={this.props.url}>{this.props.text}</Link>);
        }
        else{
            return (<button  data-toggle="modal" data-target="#formModal" className={"btn btn-outline-light rounded-pill pl-4 pr-4 fsm-theme-small-text theme-bg-blue"}>{this.props.text}</button>);
        }
    }
}

export default ReadMoreBtn
