import React, {createRef, useState} from "react"
import {graphql, Link, useStaticQuery} from "gatsby"
import ReadMoreBtn from "./read-more-btn";

const Menu = data => {

    const isMob = () => {
        return ( ( window.innerWidth <= 991 ) );
    }

    const [m1, setM1] = useState('');
    const [m1i, setM1i] = useState('fa-chevron-right');
    const [m2, setM2] = useState('');
    const [m2i, setM2i] = useState('fa-chevron-right');
    const [m3, setM3] = useState('');
    const [m3i, setM3i] = useState('fa-chevron-right');

    let q = useStaticQuery(graphql`
          query {
           allWordpressWpComplianceServices {
                edges {
                  node {
                    name
                    link
                    wordpress_id
                  }
                }
           }
           allWordpressWpLicensesAndApprovals {
                edges {
                  node {
                    name
                    link
                    wordpress_id
                  }
                }
           }
           allWordpressCategory {
                edges {
                  node {
                    name
                    link
                    acf {
                      cat_order
                    }
                    parent_element {
                      name
                    }
                  }
                }
           }
           
             pinned_post:allWordpressCategory(filter: {wordpress_id: {eq: 102}}) {
                edges {
                  node {
                    name
                    wordpress_id
                    acf {
                      select_pinned_post_url
                      select_pinned_post {
                        post_title
                        post_name
                        wordpress_id
                        post_excerpt
                      }
                    }
                  }
                }
              }
           
          }`);

    let menu = [];

    q.allWordpressCategory.edges.forEach((e, i) => {
        if (e.node.parent_element == null) {
            menu.push({name: e.node.name, link:e.node.link, children: [],wordpress_id:e.node.wordpress_id ,priority:e.node.acf != null ? parseInt(e.node.acf.cat_order) : 0 })
        }
    })
    q.allWordpressCategory.edges.forEach((e, i) => {
        if (e.node.parent_element !== null) {
            menu.forEach((e1, i2) => {
                if (e1.name === e.node.parent_element.name) {
                    menu[i2].children.push({name: e.node.name, link:e.node.link, wordpress_id:e.node.wordpress_id});
                }
            })
        }
    })
    menu.sort((a, b) =>  b.priority - a.priority);

    const onM1 = value => {

        if(isMob()){
            if(m1 === ''){
                setM1('d-block');
                setM1i('fa-chevron-down');
            }else{
                setM1('');
                setM1i('fa-chevron-right');
            }
        }
    }
    const onM2 = value => {
        if(isMob()) {
            if (m2 === '') {
                setM2('d-block');
                setM2i('fa-chevron-down');
            } else {
                setM2('');
                setM2i('fa-chevron-right');
            }
        }
    }
    const onM3 = value => {
        if(isMob()) {
            if (m3 === '') {
                setM3('d-block');
                setM3i('fa-chevron-down');
            } else {
                setM3('');
                setM3i('fa-chevron-right');
            }
        }
    }

    return (
        <div className="container-fluid mega-menu pos-sticky"  style={{zIndex:'2', top:'0'}}>

            <div className="container px-3 px-md-0 py-2">
                <div className="row">
                    <div className="col-12 px-3 px-md-0">

                        <nav className="container px-0 navbar navbar-expand-lg navbar-light pt-1 pb-1">

                            <button className="navbar-toggler" type="button" data-toggle="collapse"
                                    data-target="#navbarSupportedContent2"
                                    aria-controls="navbarSupportedContent2" aria-expanded="false" aria-label="Toggle navigation">
                                <span className="navbar-toggler-icon"> </span>
                            </button>

                            <div className="collapse navbar-collapse" id="navbarSupportedContent2">

                                <div className="mega-menu-list pt-4 pt-md-0">
                                    <ul className="nav">
                                        <li className="nav-item">
                                            <a className=" home-icon  nav-link active fsm-theme-subheading color-normal"
                                               href="/">
                                                Home
                                            </a>
                                        </li>
                                        <li className="nav-item food-categories" onClick={onM1}>
                                            <a className="nav-link fsm-theme-subheading color-normal" href="">Consumer Product <i className={"fa float-right d-md-none "+m1i }> </i> </a>
                                            <div className={"clini-submenu "+m1}>
                                                <div className="container-fluid">
                                                    <div className="row">
                                                        <div className="col-12 col-lg-7 pl-0">

                                                            <div className="card-columns">
                                                                    {menu.filter(e => e.children.length > 0).map((node, i3) => (
                                                                        <div className="card heading-menu-section border-0" key={i3}>
                                                                            <div className="pl-3 pt-3">
                                                                                <Link to={new URL(node.link).pathname} className="text-decoration-none"><h5 className="fsm-theme-menu-text font-bold theme-color-blue">{node.name}</h5></Link>
                                                                                <ul className="list-group list-group-flush">

                                                                                    {node.children.map((n2, i2) => (
                                                                                        <li className="list-group-item p-1 pl-0 fsm-theme-very-small-text bg-transparent border-0" key={i2}>
                                                                                            <Link to={new URL(n2.link).pathname} className="" dangerouslySetInnerHTML={{__html: n2.name}} />
                                                                                        </li>
                                                                                    ))}

                                                                                </ul>
                                                                            </div>
                                                                        </div>
                                                                    ))}

                                                            </div>

                                                        </div>
                                                        <div className="col-12 col-lg-2">

                                                            <ul className="list-group list-group-flush pt-3">

                                                                {menu.filter(e => e.children.length === 0).map((node, i4) => (
                                                                    <li className="list-group-item p-1 fsm-theme-small-text heading-menu-section" key={i4}>
                                                                        <Link to={new URL(node.link).pathname}><h5 className="fsm-theme-menu-text font-bold theme-color-blue">{node.name}</h5></Link>
                                                                    </li>
                                                                ))}
                                                            </ul>

                                                        </div>
                                                        <div className="col-12 col-lg-3 pr-0">

                                                            <div className="card theme-bg-blue p-3 p-md-4 h-100 rounded-0">
                                                                <div className="card-title mb-0">
                                                                    <h2 className="fsm-theme-subheading color-white mb-0"  dangerouslySetInnerHTML={{__html: q.pinned_post.edges[0].node.acf.select_pinned_post.post_title}} />
                                                                </div>

                                                                <hr className="color-white bg-white"/>
                                                                <div className="card-body p-0">
                                                                    <div className="fsm-theme-small-text color-white-alpha"  dangerouslySetInnerHTML={{__html: q.pinned_post.edges[0].node.acf.select_pinned_post.post_excerpt}} />
                                                                </div>
                                                                <div className="card-footer border-0 px-0 pb-0">
                                                                    <ReadMoreBtn type={'link'} url={new URL(q.pinned_post.edges[0].node.acf.select_pinned_post_url).pathname} text={'VIEW MORE'} />
                                                                </div>

                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </li>
                                        <li className="nav-item"  onClick={onM2}>
                                            <a className="nav-link fsm-theme-subheading color-normal" href="">Compliance Services <i className={"fa float-right d-md-none "+m2i }> </i> </a>
                                            <div className={"clini-submenu "+m2}>
                                                <div className="row">
                                                    <div className="col">

                                                        <ul className="list-group list-group-flush p-3">

                                                            {q.allWordpressWpComplianceServices.edges.map((node, i) => (
                                                                <li className="list-group-item p-1 fsm-theme-small-text heading-menu-section color-dark" key={i}>
                                                                    <Link to={new URL(node.node.link).pathname}>{node.node.name}</Link>
                                                                </li>
                                                            ))}


                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>

                                        </li>
                                        <li className="nav-item"  onClick={onM3}>
                                            <a className="nav-link fsm-theme-subheading color-normal" href="">Licenses <i className={"fa float-right d-md-none "+m3i }> </i> </a>
                                            <div className={"clini-submenu "+m3}>
                                                <div className="row">
                                                    <div className="col">
                                                        <ul className="list-group list-group-flush p-3">

                                                            {q.allWordpressWpLicensesAndApprovals.edges.map((node,i) => (
                                                                <li className="list-group-item p-1 fsm-theme-small-text heading-menu-section color-dark" key={i}>
                                                                    <Link to={new URL(node.node.link).pathname}>{node.node.name}</Link>
                                                                </li>
                                                            ))}

                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>

                                        </li>
                                        <li className="nav-item">
                                            <Link to={'/about-us/'} className="nav-link fsm-theme-subheading color-normal">About Us</Link>
                                        </li>
                                    </ul>
                                </div>


                            </div>
                        </nav>


                    </div>
                </div>

            </div>
        </div>
    )
}

export default Menu
